import NewHeader from "../components/NewHeader";
import VideoCSS from "./Video.module.css";
import Footer from "../components/Footer";
import Loader from '../components/Loader'

import ReactPlayer from 'react-player'
import React,{useState,useEffect} from 'react'

/*function Video() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);
  return (
    <div className={VideoCSS.content}>
      <NewHeader />
      <br />
      <div className={VideoCSS.download}>
        <a href="download/smartooth.apk" download>
          <img src="img/download_btn.png" alt="Smartooth APK"/> 
        </a>	
      </div>
      <Footer />
    </div>
  );
}
export default Video;*/

const Video = ({playList, index}) => {
  return (
    /*<div className={VideoCSS.content}>*/
    <div>
    	<NewHeader />
    	<br/>
        <div className={VideoCSS.parent}>
          <div className={VideoCSS.video}>
              <ReactPlayer
                  url={'video/smartooth.mp4'}    // 플레이어 url
                  width='100%'         // 플레이어 크기 (가로)
                  height='100%'        // 플레이어 크기 (세로)
                  playing={true}        // 자동 재생 on
                  muted={true}          // 자동 재생 on
                  controls={true}       // 플레이어 컨트롤 노출 여부
                  light={false}         // 플레이어 모드
                  pip={true}            // pip 모드 설정 여부
                  config={{ file: { attributes: { controlsList: 'nodownload' }}}} //다운로드 금지 
                  onContextMenu={e => e.preventDefault()} // 우클릭 금지
              />
          </div>
        </div>
        <Footer/>
      </div>
  )
}

export default Video;
