import NewHeader from "../components/NewHeader";
import ProductCSS from "./Product.module.css";
import Subtitle from "../components/Subtitle";
import WhatIsSmartooth from "../components/Product/WhatIsSmartooth";
import Explanation from "../components/Product/Explanation";
import GuideVideo from "../components/Product/GuideVideo";
import Cradle from "../components/Product/Cradle";
import Footer from "../components/Footer";
import Loader from '../components/Loader'
import SmartoothAppCSS from "./SmartoothApp.module.css";
import React,{useState,useEffect} from 'react'

function Product() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);
  return (
    <div className={ProductCSS.content}>
      <NewHeader />
      <div className={SmartoothAppCSS.container}>
          <p>홈페이지 개편중입니다.<br/><br/></p>
          <p>Our website is currently being reorganized.<br/><br/></p>
          <p>ホームページ改編中です。<br/><br/></p>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
      </div>  
      <Footer />
      
    </div>
  );
}
export default Product;
