import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./routes/Home";
import Product from "./routes/Product";
import AboutUs from "./routes/AboutUs";
import Download from "./routes/Download";
import SmartoothApp from "./routes/SmartoothApp";
import Premium from "./routes/Premium";
import Video from "./routes/Video";
// import {Helmet} from './react-helmet'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="product" element={<Product />} />
        <Route path="about_us" element={<AboutUs />} />
        <Route path="/" element={<Home />} />
        <Route path="download" element={<Download />} />
        <Route path="smartoothapp" element={<SmartoothApp />} />
        <Route path="premium" element={<Premium />} />
        <Route path="video" element={<Video />} />
      </Routes>
    </Router>
  );
}

export default App;
