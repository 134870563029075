import NewHeader from "../components/NewHeader";
import PremiumCSS from "./Premium.module.css";
import Footer from "../components/Footer";
import Loader from '../components/Loader';
import React,{useState,useEffect} from 'react';

function Premium() {
  const imagestyle = {
      height: "180px",
      width: "180px",
  };
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);
  return (
    <div className={PremiumCSS.content}>
      <NewHeader />
      <br />
      <br />
      <div className={PremiumCSS.wrap}>
        <a href="https://drive.google.com/file/d/1AKy_RXUXSQsCs4qHPsLQYrK7km821jtS/view" download>
          <img className={PremiumCSS.downloadBtn} src="img/download_btn.png" alt="Smartooth APK" style={imagestyle}/><br />
          Smartooth premium Android APK Download.
        </a>	
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}
export default Premium;
