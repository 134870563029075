import React, { useState, useEffect } from "react";
import AboutUsCSS from "./AboutUs.module.css";
import NewHeader from "../components/NewHeader";
import Subtitle from "../components/Subtitle";
import Footer from "../components/Footer";
import NewTimeline from "../components/AboutUs/newTimeline";
import AboutSmartooth from "../components/AboutUs/AboutSmartooth";
// import NewMembers from "../components/AboutUs/NewMembers";
import SmartoothAppCSS from "./SmartoothApp.module.css";
import NewMembersStatic from '../components/AboutUs/NewMembersStatic'

import Loader from '../components/Loader'

function AboutUs() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <div className={AboutUsCSS.content}>
      <NewHeader />      
     <div className={SmartoothAppCSS.container}>
          <p>홈페이지 개편중입니다.<br/><br/></p>
          <p>Our website is currently being reorganized.<br/><br/></p>
          <p>ホームページ改編中です。<br/><br/></p>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
      </div> 
      <Footer />
    </div>
  );
}
export default AboutUs;
