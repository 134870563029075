import NewHeader from "../components/NewHeader";
import DownloadCSS from "./Download.module.css";
import Footer from "../components/Footer";
import Loader from '../components/Loader';
import React,{useState,useEffect} from 'react';

function Download() {
  const imagestyle = {
      height: "180px",
      width: "180px",
  };
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);
  return (
    <div className={DownloadCSS.content}>
      <NewHeader />
      <br />
      <br />
        <div className={DownloadCSS.userwrap}>
            <div className={DownloadCSS.userimage}>
                <img src="https://www.smartooth.co/img/download_top.png" alt="Smartooth" width="800px" />
            </div>
            <div className={DownloadCSS.usertext}>
                <span className={DownloadCSS.usertextp1}>구강 진단장비 & 데이터 서비스</span><br />
                <span className={DownloadCSS.usertextp2}>구강관리 토탈 솔루션</span><br />
                <span className={DownloadCSS.usertextp3}>SMARTOOTH</span><br />    
            </div>
            <div className={DownloadCSS.usertext}>
                <img src="https://www.smartooth.co/img/download_top_c.png" alt="Smartooth" width="300px" />
            </div>
        </div>
        <div className={DownloadCSS.userparent1}>
            <div className={DownloadCSS.userwrap1}>
                <div className={DownloadCSS.userimage1}>
                    <a href="/download/SmartoothPremium_DentService_20241004_28.apk" type="application/vnd.android.package-achive" download><img src="https://www.smartooth.co/img/download_dent.png" alt="Smartooth" width="230px" /></a>
                </div>
                <div className={DownloadCSS.usertext1}>
                    <p>Version 1.1.28</p>
                </div>
                <div className={DownloadCSS.rect1}>
                    <a href="/download/기기 및 SW 소개 ver.1.6_20230821.pdf" download>
                      <img src="https://www.smartooth.co/img/bg_rect_dent.png" alt="rect" width="230px" />
                      <p>매뉴얼(기기 및 SW 소개)</p>
                      <p>DOWNLOAD</p>
                    </a>
                </div> 
                <div className={DownloadCSS.rect2}>
    <a href="/download/어플리케이션 설치 조건 및 방법 ver.1.1_20230720.pdf" download>
                    <img src="https://www.smartooth.co/img/bg_rect_dent.png" alt="rect" width="230px" />
                    <p>어플리케이션 설치 방법</p>
                    <p>DOWNLOAD</p>
    </a>
                </div>  
                <div className={DownloadCSS.rect3}>
    <a href="/download/디스플레이 기기 사용 매뉴얼 ver1.4_20230720.pdf" download>
                    <img src="https://www.smartooth.co/img/bg_rect_dent.png" alt="rect" width="230px" />
                    <p>디스플레이 메뉴얼</p>
                    <p>DOWNLOAD</p>
    </a>
                </div>     
            </div>
            <div className={DownloadCSS.userwrap1}>
                <div className={DownloadCSS.userimage1}>
                    <a href="/download/smartooth_phc_service_20241114.apk" type="application/vnd.android.package-achive" download><img src="https://www.smartooth.co/img/download_healthcare.png" alt="Smartooth" width="230px" /></a>
                </div>
                <div className={DownloadCSS.usertext1}>
                    <p>Version 1.0.4</p>
                </div>
            </div>
            <div className={DownloadCSS.userwrap1}>
                <div className={DownloadCSS.userimage1}>
                    <a href="/download/smartooth_school_service_20240711_1.2.8.apk" type="application/vnd.android.package-achive" download><img src="https://www.smartooth.co/img/download_kinder.png" alt="Smartooth" width="230px" /></a>
                </div>
                <div className={DownloadCSS.usertext1}>
                    <p>Version 1.2.8</p>
                </div>
            </div>
        </div>
        <div className={DownloadCSS.userparent1}>
            <div className={DownloadCSS.userwrap1}>
                <div className={DownloadCSS.userimage1}>
                    <a href="https://play.google.com/store/apps/details?id=co.smartooth.smartooth_dpapp" target="_blank" type="application/vnd.android.package-achive" download><img src="https://www.smartooth.co/img/download_dpapp.png" alt="Smartooth" width="230px" /></a>
                </div>
                <div className={DownloadCSS.usertext1}>
                    <p>Version 1.0.2</p>
                </div>
                <div className={DownloadCSS.rect1}>
    <a href="https://apps.apple.com/kr/app/smartooth-dp/id6670414479" target="_blank" download>
                    <img src="https://www.smartooth.co/img/bg_rect_dp2.png" alt="rect" width="230px" />
                    <p>IOS App Donwload</p>
                    <p>DOWNLOAD</p>
    </a>
                </div>     
            </div>
            <div className={DownloadCSS.userwrap1}>
                <div className={DownloadCSS.userimage1}>
                    <a href="/download/smartooth_20240829_4.apk" type="application/vnd.android.package-achive" download><img src="https://www.smartooth.co/img/download_general.png" alt="Smartooth" width="230px" /></a>
                </div>
                <div className={DownloadCSS.usertext1}>
                    <p>Version 1.0.4</p>
                </div>
                <div className={DownloadCSS.rect1}>
                    <a href="/download/Introduction to Devices & SW_2024.pdf" download>
                      <img src="https://www.smartooth.co/img/bg_rect_dp2.png" alt="rect" width="230px" />
                      <p>디바이스 & SW 소개</p>
                      <p>DOWNLOAD</p>
                    </a>
                </div>       
            </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      <Footer />
    </div>
  );
}
export default Download;
