import NewHeader from "../components/NewHeader";
import KickStarter from "../components/KickStarter";
import MainVanner from "../components/MainVanner";
import Subtitle from "../components/Subtitle";
// import Steps from "../components/Steps";
import StepStatic from '../components/StepsStatic'
import Footer from "../components/Footer";
import HomeCSS from "./Home.module.css";
import MobileApp from "../components/Product/MobileApp";
import Loader from "../components/Loader";
import SmartoothAppCSS from "./SmartoothApp.module.css";
import React, {useState, useEffect} from 'react'

function Home() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (


    <div className={HomeCSS.base}>
    <NewHeader />
      <div className={SmartoothAppCSS.container}>
          <p>홈페이지 개편중입니다.<br/><br/></p>
          <p>Our website is currently being reorganized.<br/><br/></p>
          <p>ホームページ改編中です。<br/><br/></p>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
      </div>  
    <Footer />
    </div>
  );
}
export default Home;
